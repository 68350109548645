import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ash/code/blog/src/layouts/MDXLayout.tsx";
import { SectionHeader } from 'components/SectionHeader.tsx';
import { TalkTile } from 'components/TalkTile.tsx';
import { ReactResponsiveEmbed } from 'components/ReactResponsiveEmbed.tsx';
import { SectionTitle } from 'components/SectionTitle.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I love sharing knowledge – in the form of blog posts, podcasts, open source code,
books – and over the past few years, I’ve grown really fond of speaking. I’ve
gotten good at it, too.`}</p>
    <ReactResponsiveEmbed src="https://player.vimeo.com/video/270512761" allowFullScreen mdxType="ReactResponsiveEmbed" />
    <SectionHeader banner="/assets/speaking/upcomingtalks.jpg" dim mdxType="SectionHeader">
  <SectionTitle mdxType="SectionTitle">Coming Soon to a Conference Near You</SectionTitle>
    </SectionHeader>
    {!!props.pageContext.frontmatter.upcoming_talks ? <ol style={{
      margin: 0
    }}>
    {props.pageContext.frontmatter.upcoming_talks.map(t => <TalkTile talk={t} key={t.dates} mdxType="TalkTile" />)}
  </ol> : 'No upcoming talks at this time.'}
    <SectionHeader banner="/assets/speaking/pasttalks.jpg" dim mdxType="SectionHeader">
  <SectionTitle mdxType="SectionTitle">Past Awesome Talks</SectionTitle>
    </SectionHeader>
    <ol style={{
      margin: 0
    }}>
  {props.pageContext.frontmatter.past_talks.map(t => <TalkTile talk={t} key={t.dates} mdxType="TalkTile" />)}
    </ol>
    <SectionHeader banner="/assets/speaking/getintouch.jpg" dim mdxType="SectionHeader">
  <SectionTitle mdxType="SectionTitle">Get in Touch</SectionTitle>
    </SectionHeader>
    <p>{`Usually I like to deliver a combination of technical knowledge and inspirational messages. Context for technical know-how is critical. Understanding `}<em parentName="p">{`what`}</em>{` you’re doing is pointless if you don’t understand `}<em parentName="p">{`why`}</em>{` you’re doing it.`}</p>
    <p>{`If you’re interested in having me speak, `}<a parentName="p" {...{
        "href": "mailto:ash@ashfurrow.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`get in touch`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      